import React from 'react';
import { Image } from 'react-datocms';
import { useRouter } from 'next/router';

export const TrustbarGrid = ({ logos, bgColor = 'none', className = '', phoneImageMaxWidth = '', mediumImageMaxWidth = '', flexWrap = '', wide = false, }) => {
    // 'trustbar-container pb-0 lg:pb-10 lg:mt-4 default-px'
    return (
        <section id='trustbar2' className={`trustbar-container default-px ${className}`}>
            <div className={'default-max-w mx-auto relative overflow-hidden ' + (wide ? 'wide' : '')}>
                <div className='trustbar-logos mx-auto'>
                    {logos?.map((logo: any) => (
                        <div key={logo?.url} className={`trustbar-logo-item item-title-${logo?.title}`}>
                            {logo?.responsiveImage ? (
                                <Image data={{ ...logo?.responsiveImage, bgColor: '#ffffff00' }} />
                            ) : (
                                <img
                                    src={logo?.url}
                                    alt={logo?.alt ?? logo?.title}
                                    loading='lazy'
                                    className='max-w-[200px]'
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <style jsx>
                {`
                    .trustbar-container {
                        width: 100%;
                        background-color: ${bgColor};
                        @media (max-width: 768px) {
                            padding-left: 0px;
                            padding-right: 0px;
                        }

                        .trustbar-wrapper {
                            max-width: 1400px;
                            margin: 0 auto;
                            position: relative;
                            overflow: hidden;
                        }

                        .trustbar-logos {
                            //display: grid;
                            // grid with max colunm witdth of 200 pixels
                            //grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                            gap: 10px;

                            display: flex;

                            
                            @media (max-width: 768px) {
                                // flex-wrap: ${flexWrap};
                                // justify-content: center;
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr 1fr;
                            }
                            //grid-template-columns: 1fr 1fr 1fr 1fr;
                            //display: flex;
                            //flex-wrap: wrap;
                            //justify-content: stretch;
                            //align-items: center;

                            //
                            //& > div {
                            //    display: flex;
                            //    justify-content: space-evenly;
                            //    justify-items: center;
                            //    align-content: space-evenly;
                            //    align-items: center;
                            //
                            //    @media (max-width: 480px) {
                            //        flex-direction: column-reverse;
                            //    }
                            //
                            //    //animation: 28s linear 0s infinite normal none running animation-logo;
                            //}
                            justify-content: center;
                            align-content: center;

                            .trustbar-logo-item {
                                margin: auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                //background: #ff000044;
                                //max-width: 50%;
                                //margin: 14px;
                                //max-width: 300px;

                                @media (max-width: 768px) {
                                    flex-wrap: wrap;
                                    //background: red;
                                    max-width: ${mediumImageMaxWidth};
                                }

                                @media (max-width: 768px) {
                                    flex-wrap: wrap;
                                    max-width: ${phoneImageMaxWidth};
                                }

                                img {
                                    //max-width: 100%;
                                    width: 100%;
                                    height: auto;
                                }

                                width: 100%;

                                &.item-title-billboard {
                                    width: 65%;
                                }

                                &.item-title-us-news {
                                    width: 79%;
                                }

                                &.item-title-holon {
                                    width: 65%;
                                }


                                &.item-title-codie {
                                    width: 71%;
                                }


                                &.item-title-nsf {
                                    width: 100%;
                                    position: relative;
                                    top: 30%;
                                }

                                &.item-title-doe {
                                    width: 100%;
                                    //background: red;
                                }

                                &.item-title-ies {
                                    width: 120%;
                                }

                                @media (max-width: 768px) {
                                    &.item-title-holon, &.item-title-codie, &.item-title-ies, &.item-title-nsf {
                                        display: none;
                                    }
                                }

                                /*
                                                                img {
                                                                    height: auto;
                                                                    width: 100%;
                                                                    background: orange;
                                                                }
                                
                                

                                
                                                                */
                            }

                            /*
                            
                                                        .trustbar-logo-item.item-title-SIIA {
                                                            @media (max-width: 480px) {
                                                                display: none;
                                                            }
                                                        }
                            
                                                        .trustbar-logo-item.item-title-mEA {
                                                            @media (max-width: 480px) {
                                                                display: none;
                                                            }
                                                        }
                            
                                                        .trustbar-logo-item.item-title-NSBA {
                                                            @media (max-width: 480px) {
                                                                display: none;
                                                            }
                                                        }*/
                        }

                        .first-section-homepage-trustbar {
                            @media (max-width: 800px) {
                                padding: 17px 20px 25px;
                            }
                        }

                        @keyframes animation-logo {
                            0% {
                                transform: translateX(0px);
                            }
                            100% {
                                transform: translateX(-100%);
                            }
                        }

                        .trustbar-container.press {
                            @media (max-width: 480px) {
                                display: none;
                            }
                        }

                        &.center-on-medium {
                            .trustbar-logos {
                                justify-content: center;
                                gap: 20px;

                                @media (max-width: 768px) {
                                    gap: 40px;
                                    //justify-content: space-between;
                                    margin: auto;
                                    //background: orange;
                                }

                                @media (max-width: 550px) {
                                    gap: 10px;
                                    margin: 0;
                                    //background: green;
                                }
                            }

                            @media (max-width: 800px) {
                                //padding: 48px 40px;
                            }

                            @media (max-width: 480px) {
                                //padding: 30px 20px;
                            }
                        }


                    }
                `}
            </style>
        </section>
    );
};
