import React from 'react';
import { Trustbar2 } from '@/components/Trustbar2/Trustbar';
import { TrustbarGrid } from "@/components/Trustbar2/TrustbarGrid";

const ImageBarSection = ({ images, className = null, grid = false }) => {
    const mobileImages = images.slice(0, 8);
    return (
        <div className={'w-full pb-[0px] pt-4 sm:mb-16' + className}>
            <div className='w-full max-w-[1400px] mx-auto '>
                {grid ? <TrustbarGrid
                    // className='bg-green-100'
                    logos={mobileImages}
                    phoneImageMaxWidth='100px'
                    flexWrap='wrap'
                /> : <Trustbar2
                    // className='bg-green-100'
                    logos={mobileImages}
                    phoneImageMaxWidth='100px'
                    flexWrap='wrap'
                />}
            </div>
        </div>
    );
};

export default ImageBarSection;
